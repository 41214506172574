<template>
  <div class="mobilefooter">
    <GlobalLogo class="footerlogo"></GlobalLogo>
    <div class="footerlink">
      <ul class="link_list">
        <li class="link_item" @click="gotoabout">About Us</li>
        <li class="link_item" @click="gotoview('mservice')">Terms of Service</li>
        <!-- <li class="link_item" @click="$router.push('mservice')">Terms of Service</li> -->
        <li class="link_item" @click="gotoview('mprivacypolicy')">Privacy Policy</li>
        <li class="link_item" @click="gotocontact">Contact Us</li>
        <li class="link_item" @click="gotoview('mrefundpolicy')">Refund Policy</li>
        <li class="link_item" @click="gotoview('mcancellationpolicy')">Cancellation Policy</li>
      </ul>
    </div>
    <div class="footertext">
      <p>3RD FLOOR, B-8, SECTOR 2 NOIDA,<br /> Noida, Gautam Buddha Nagar, Uttar Pradesh, 201301</p>
      <p style="margin:.12rem 0">CUBETECH INNOVATIONS PRIVATE LIMITED</p>
      <p>© 2022 LEFIN INFOTECH LTD. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
import GlobalLogo from '../GlobalLogo/GlobalLogo.vue'
export default {
  components:{
    GlobalLogo
  },
    methods: {
        gotoabout() {
            this.$router.push({
                path: "/mobile",
                query: {
                    mobileabout: 1
                }
            });
            return;
        },
        gotocontact() {
            this.$router.push({
                path: "/mobile",
                query: {
                    mobilecontact: 1
                }
            });
            return;
        },
        gotoview(path) {
            this.$router.push(`${path}`);
            document.documentElement.scrollTop = 0;
        }
    },
    components: { GlobalLogo }
}
</script>

<style lang="scss" scoped src="./mobilefooter.scss">
</style>