<template>
  <div class="detail">
    <div class="wrap">

      <div class="header_nav">
        <div class="l">
          <GlobalLogo></GlobalLogo>
        </div>
        <div class="r">
          <Nav :hrefList="hrefList"></Nav>
        </div>
      </div>
      <p class="detail_title">{{  deteil_title  }}</p>
    </div>

  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
import GlobalLogo from '../GlobalLogo/GlobalLogo.vue'
export default {
  props: ['deteil_title'],
  components: {
    Nav,
    GlobalLogo
  },
  methods: {
    gotohome() {
      this.$router.push('/')
    }
  },
  data() {
    return {
      hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy', 'Cancellation Policy'],
    }
  },

}
</script>

<style lang="scss" scoped src="./detail.scss">
</style>