<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    const oHtml = document.getElementsByTagName("html")[0]
    const width = oHtml.clientWidth
    if (width <= 750) {
      this.$router.push('/mobile')
    }
    else {
      this.$router.push('/')
    }

    window.addEventListener('resize', () => {
      const oHtml = document.getElementsByTagName("html")[0]
      const width = oHtml.clientWidth
      if (width <= 750) {
        this.$router.push('/mobile')
      }
      else {
        this.$router.push('/')
      }
    })
  }
}
</script>
<style lang="scss">
#app {}
</style>
