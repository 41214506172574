<template>
  <div class="mdetail">
    <div class="mdetail_head">
      <GlobalLogo></GlobalLogo>
      <div class="navlist" @click="gotonav"><img src="../img/mobilenavlist.png" /></div>
    </div>
    <div class="detail_title">{{ deteil_title }}</div>
  </div>
</template>

<script>
import GlobalLogo from '../GlobalLogo/GlobalLogo.vue';
export default {
  props: ['deteil_title'],
  components:{
    GlobalLogo
  },
  methods: {
    gotohome() {
      this.$router.push('/mobile')
    },
    gotonav() {
      console.log('navvv');
      // this.$refs.shownav.style.display = 'block'
      this.$router.push('/mnav')
      // this.changenavshow(true)
    },
  },
}
</script>

<style lang="scss" scoped src="./mdateil.scss">
</style>