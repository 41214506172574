<template>
  <div class="footer">
    <div class="wrap">
      <div class="l" @click="gotohome">
        <img class="logo" src="../img/logo.png" />
        <span style="font-weight: 800;">&nbsp;Photo Video</span>
        <span>&nbsp;Maker</span>
      </div>
      <Nav :hrefList="hrefList" style="width:70%; color:#fff"></Nav>
      <p style="text-align: right; color: rgba(255, 255, 255, 0.6); font-size: 14px;">CUBETECH INNOVATIONS PRIVATE LIMITED</p>
      <div class="footer_text">
        <p style="color: rgba(255, 255, 255, 0.6);">© 2022 Photo Video Maker All Rights Reserved.</p>
        <p style="color: rgba(255, 255, 255, 0.6);">3RD FLOOR, B-8, SECTOR 2 NOIDA,<br /> Noida, Gautam Buddha Nagar, Uttar Pradesh, 201301</p>
      </div>
    </div>

  </div>
</template>

<script>
import Nav from '../Nav/Nav.vue'
export default {
  components: {
    Nav,
  },
  data() {
    return {
      hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy', 'Cancellation Policy']
    }
  },
  methods: {
    gotohome() {
      console.log('home');
      this.$router.push('/')
    },
  }
}
</script>

<style lang="scss" scoped src="./footer.scss">
</style>