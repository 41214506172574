<template>
  <div class="contactus" id="contact">
    <div class="wrap">
      <div class="contactusl">
        <p class="infotitle">Contact Us</p>
        <div class="underline"></div>
        <p class="contactusltext" style="font-family: Ubuntu-Medium;">CUBETECH INNOVATIONS PRIVATE LIMITED</p>
        <p class="contactusltext" style="color: #667085;">Customer care number: +91 6209007704</p>
        <p class="contactusltext" style="color: #667085;">Customer care email ID: contact@photovideos.app</p>
      </div>
      <div class="contactusr">
        <div class="input_concent">
          <div class="input_item">
            <img src="../img/user_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.user" placeholder="Please enter name">
          </div>
          <div class="input_item">
            <img src="../img/email_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.email" placeholder="Please enter E-mail">
          </div>
          <div class="input_item">
            <img src="../img/phone_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.phone" placeholder="Please enter Phone">
          </div>
        </div>
        <textarea class="message_input" type="textarea" placeholder="Please enter Message"
          v-model="insertInfo.message"></textarea>
        <div class="send" @click="thro">
          Send
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Global from '@/assets/js/Global'
export default {
  data() {
    return {
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
    }
  },
  methods: {
    thro() {
      Global.throttle(this.SendMessage, 5000)
    },

    SendMessage() {
      if (this.insertInfo.user == '' || this.insertInfo.email == '' || this.insertInfo.phone == '' || this.insertInfo.message == '') {
        this.$message({
          message: 'Please fill in all the information.',
          type: 'warning'
          //type: warning
          // this.$message.error('shibai');
        })
        return
      }
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./contactus.scss">
</style>