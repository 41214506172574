<template>
  <div class="mobileh">
    <div class="mdetail_head">
      <!-- <div class="logo">
        <img src="../img/logo.png" /> &nbsp;
        <span style="font-weight: 800;">Photo Video</span>&nbsp;
        <span>Maker with Song</span>
      </div> -->
      <GlobalLogo></GlobalLogo>
      <div class="navlist" @click="gotonav"><img src="../img/mobilenavlist.png" /></div>
    </div>
    <div class="banner">
      <img src="../img/mbheaderimg1.png" />
    </div>
    <div class="headertitle">
      <img src="../img/mbheaderimg2.png" />
    </div>
    <div class="freedown" @click="downLoadAPK">
      <span>Free Download</span><img src="../img/headertextgoto.png" >
    </div>
  </div>
</template>

<script>
import Mdetail from '@/components/Mdetail/Mdetail.vue';
import GlobalLogo from '../GlobalLogo/GlobalLogo.vue'; 
import {mapMutations} from 'vuex'
export default{
  components:{
    Mdetail,
    GlobalLogo,
},
  methods:{
    ...mapMutations(['changenavshow']),
    gotonav() {
      this.$router.push('/mnav')
      console.log('navvv');
      // this.$refs.shownav.style.display = 'block'

      // this.changenavshow(true)
    },
    downLoadAPK() {
      // window.open('./apps/wakapro/wakapro_release.apk', '_blank');
      var timestamp = Date.parse(new Date());
      var apklink = `./apps/Photovideo-Release.apk?t=${timestamp}`;
      //----- 创建隐藏的可下载链接
      var eleLink = document.createElement('a');
      eleLink.setAttribute("href", apklink);
      eleLink.setAttribute("download", 'Photovideo-Release.apk');
      eleLink.style.display = 'none';
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    }
  },

}
</script>

<style lang="scss" scoped src="./mobileheader.scss">
</style>