<template>
  <div class="mobile_home">
    <MobileHeader></MobileHeader>
    <Mobilecontent></Mobilecontent>
    <Mobilefooter></Mobilefooter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import MobileHeader from '@/components/MobileHeader/Mobileheader.vue'
import Mobilecontent from '@/components/MobileContent/Mobilecontent.vue';
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue';
export default {
  computed:{
    ...mapState(['navshow'])
  },
  components: {
    MobileHeader,
    Mobilecontent,
    Mobilefooter,
  }
}
</script>

<style lang="scss" scoped src="./mobile.scss">
</style>