<template>
  <div>
    <Mdetail :deteil_title="'Cancellation Policy'"></Mdetail>
    <div class="detail_text_box detail_text">
      <p>Our cancellation policy:</p>
      <p>Client satisfaction lies at the core of our services and we ensure that in every possible way. At Baat app,
        even our cancellation and refund policy of in-app purchases and virtual items are very transparent and different
        for each service we offer.</p>
      <p>How is the cancellation procedure?</p>
      <p>Send us your cancellation request by telephone, email or fax</p>
      <p>It will be collected at our accounts and billing department</p>
      <p>You will receive a confirmation reply from us. Until then, the request will remain invalid</p>
      <p>The email we send in reply will be considered proof of canceling the service</p>
      <p>Note: Clients need to place their cancellation request within 12 hours of placing the order. Baat will only
        accept it when the project has not been started yet.</p>
    </div>
    <Mobilefooter></Mobilefooter>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue'
import Mdetail from '@/components/Mdetail/Mdetail.vue'
export default {
  components: {
    Mdetail,
    Mobilefooter,
  },
    computed: {
    ...mapState(['navshow'])
  }

}
</script>

<style>
</style>