<template>
  <div class="mobile_content">
    <div class="bg">
      <img src="../img/mbcontent1.png" alt="">
    </div>
    <div class="aboutus" id="about">
      <div class="aboutusinfo">
        <img class="abouticon" src="../img/content_img2.png" alt="">
        <div class="aboutusinfotext">
          <p class="infotitle">Who Are We?</p>
          <p style="font-size: .16rem; margin: .16rem 0; font-family: Ubuntu-Bold;">Photo Video Maker :
            Particle Video Status</p>
          <p style="color:#667085; margin-bottom: .16rem  ;">Enjoy our best Video Templates for Greetings, Birthday,
            Love, Festival, Motivational, Sad, Lyrical, God, Dialogue and Shayri and more…. To make Videos and
            Slideshows with Photos. Create your amazing video story using Photo to Video Story maker and share them with
            friends on social media.</p>
          <p style="color:#667085; font-family: Ubuntu-Medium">CUBETECH INNOVATIONS PRIVATE LIMITED</p>
        </div>

      </div>
    </div>
    <div class="wecando">
      <div class="contenticon">
        <img src="../img/mbcontent2.png" />
        <p class="infotitle" style="font-weight: 800; margin: .22rem 0 .24rem;">
          What you can do on Photo Video Maker? ALL OF THESE!!
        </p>
        <p>- New or Amazing Attractive Fast Animated Video Templates</p>
        <p>- Number of Amazing Video Templates</p>
        <p>- Cube Motion, Valentine, Baby Video, Love Effect and festival Special Videos</p>
        <p>- Add your Favorite Photo</p>
        <p>- Preview and Export videos</p>
        <p>- Easy to Save and Share Video</p>
      </div>
    </div>
    <div class="letnow">
      <p class="infotitle">Let's Edit NOW</p>
      <div class="imgswiper" ref="imgswiper" @mousewheel="mouseDownAndMove">
        <ul class="imglist" ref="imglist">
          <li ref="imgitem1" class="imgitem" v-for="(item, index) in imgurl" :key="index">
            <img :src="item.url" alt="">
          </li>
        </ul>
        <ul class="imglist1" ref="imglist1">
          <li class="imgitem" v-for="(item, index) in imgurl" :key="'1' + index">
            <img :src="item.url" alt="">
          </li>
        </ul>
      </div>
    </div>
    <MobileContactus  id="contact"></MobileContactus>
  </div>
</template>

<script>

import MobileContactus from '../MobileContactus/MobileContactus.vue'
export default {
  components:{
    MobileContactus
  },
  data() {
    return {
      imgurl: [
        { url: require('../img/showimg/01.png') },
        { url: require('../img/showimg/02.png') },
        { url: require('../img/showimg/03.png') },
        { url: require('../img/showimg/04.png') },
        { url: require('../img/showimg/05.png') },
        { url: require('../img/showimg/06.png') },
        { url: require('../img/showimg/07.png') }
      ],
    }
  },
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["mobilecontact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["mobileabout"]) {
          this.toAboutus()
        }
      }
    }
  },
  mounted() {
    if (this.$route.query["mobilecontact"]) {
      this.toConcatUs()
    }
    if (this.$route.query["mobileabout"]) {
      this.toAboutus()
    }

  },
  methods: {
    mouseDownAndMove() {
      const imgswiper = this.$refs.imgswiper
      if (imgswiper.scrollLeft > 1232) {
        imgswiper.scrollLeft = 0
      }
      else if (imgswiper.scrollLeft <= 0) {
        imgswiper.scrollLeft = 1232
      }
    },
    toConcatUs() {
      document.getElementById(`contact`).scrollIntoView()
      this.$router.push('/mobile')
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView()
      this.$router.push('/mobile')
    },
  }
}
</script>

<style lang="scss" scoped src="./mobilecontent.scss">
</style>