<template>
  <div class="logo">
    <img src="../img/logo.png" @click="gotohome"/> &nbsp;
    <span style="font-weight: 800;">Photo Video</span>&nbsp;
    <span>Maker</span>
  </div>
</template>

<script>
export default {
  methods: {
    gotohome() {
      this.$router.push('/mobile')
    }
  }
}
</script>

<style lang="scss" scoped src="./logo.scss">
</style>