<template>
  <div class="mobilecontactus_box">
    <p class="infotitle" style="margin-bottom:.24rem;text-align: center;">Contact Us </p>
    <div class="underline"></div>
    <div class="mobilecontactus_title">
      <p style="font-size: .16rem;margin: .16rem 0;font-family: Ubuntu-Medium">CUBETECH INNOVATIONS PRIVATE LIMITED
      </p>
      <p>Customer care number: +91 6209007704</p>
      <p style="margin: .16rem 0 .24rem;">Customer care email ID: contact@photovideos.app</p>

    </div>
    <div class="mobileconbody">
      <div class="input_item marbtom">
        <img src="../img/user_input.png">
        <input type="text" placeholder="Please enter name" v-model="insertInfo.user" />
      </div>
      <div class="input_item marbtom">
        <img src="../img/email_input.png">
        <input type="text" placeholder="Please enter E-mail" v-model="insertInfo.email" />
      </div>
      <div class="input_item marbtom">
        <img src="../img/phone_input.png">
        <input type="text" placeholder="Please enter Phone" v-model="insertInfo.phone" />
      </div>
      <textarea class="message_input marbtom" placeholder="Please enter Message"
        v-model="insertInfo.message"></textarea>
      <div class="send marbtom" :plain="true" @click="thro">Send</div>

    </div>
  </div>
</template>

<script>
import Global from '@/assets/js/Global';
export default {
  data() {
    return {
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
    }
  },
  methods: {
    thro() {
      Global.throttle(this.SendMessage, 5000)
    },

    SendMessage() {
      if (this.insertInfo.user == '' || this.insertInfo.email == '' || this.insertInfo.phone == '' || this.insertInfo.message == '') {
        this.$message({
          message: 'Please fill in all the information.',
          type: 'warning'
          //type: warning
          // this.$message.error('shibai');
        })
        return
      }
      console.log('sendddd');
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilecontactus.scss">
</style>