<template>
  <div class="home">
    <Header></Header>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import Content from '@/components/Content/Content';

export default {
  name: 'Home',
  components: {
    Header,
    Content,
    Footer,
}
}
</script>
