<template>
  <div class="content">
    <div class="aboutwe wrap">
      <img class="content_img1" src="../img/content_img1.png" />
      <div class="aboutweinfo" id="about">
        <img class="content_img2" src="../img/content_img2.png">
        <p class="infotitle">Who Are We?</p>
        <p style="font-size:20px;margin: 20px 0;font-family: Ubuntu-Bold;">Photo Video Maker : Particle Video
          Status</p>
        <p style="font-size:14px;color:#667085;line-height: 30px;">Enjoy our best Video Templates for Greetings,
          Birthday, Love, Festival,
          Motivational, Sad, Lyrical, God, Dialogue and Shayri and more…. To make Videos and Slideshows with Photos.
          Create your amazing video story using Photo to Video Story maker and share them with friends on social media.
        </p>
        <p style="font-size:18px;margin-top: 24px;font-family: Ubuntu-Medium;">CUBETECH INNOVATIONS PRIVATE LIMITED
        </p>
      </div>

    </div>
    <div class="wecaninfo">
      <div class="wrap">
        <div class="wecaninfol">
          <p class="infotitle" style="margin-bottom:19px">What you can do on Photo Video Maker? ALL OF THESE!!</p>
          <p class="infotitlei">- New or Amazing Attractive Fast Animated Video Templates </p>
          <p class="infotitlei">- Number of Amazing Video Templates </p>
          <p class="infotitlei">- CubeMotion, Valentine, Baby Video, Love Effect and festival Special Videos </p>
          <p class="infotitlei">- Add your Favorite Photo </p>
          <p class="infotitlei">- Previewand Export videos </p>
          <p class="infotitlei">- Easy to Save and Share Video</p>
        </div>
        <div class="wecaninfor"><img src="../img/content_img3.png" /></div>
      </div>
    </div>
    <div class="letnow">
      <div class="wrap">
        <p class="infotitle" style="margin-bottom:.5rem;font-family: Ubuntu-Bold">Let's Edit NOW</p>
      </div>
      <div class="imgswiper" ref="imgswiper" @mousewheel="mouseDownAndMove">
        <ul class="imglist" ref="imglist">
          <li ref="imgitem1" class="imgitem" v-for="(item, index) in imgurl" :key="index">
            <img :src="item.url" alt="">
          </li>
        </ul>
        <ul class="imglist1" ref="imglist1">
          <li class="imgitem" v-for="(item, index) in imgurl" :key="'1' + index">
            <img :src="item.url" alt="">
          </li>
        </ul>
      </div>
    </div>
    <Contactus id="contact"></Contactus>
  </div>
</template>

<script>
import Contactus from '@/components/Contactus/Contactus.vue'
export default {
  components:{
    Contactus
  },
  watch: {
    "$route": {
      handler(val) {
        console.log(val);
        if (this.$route.query["contact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["about"]) {
          console.log('gotobout');
          this.toAboutus()
        }
      }
    }
  },
  data() {
    return {
      imgurl: [
        { url: require('../img/showimg/01.png') },
        { url: require('../img/showimg/02.png') },
        { url: require('../img/showimg/03.png') },
        { url: require('../img/showimg/04.png') },
        { url: require('../img/showimg/05.png') },
        { url: require('../img/showimg/06.png') },
        { url: require('../img/showimg/07.png') }
      ]
    }
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query["contact"]) {

      this.toConcatUs()
    }
    if (this.$route.query["about"]) {
      console.log('gotobout');
      this.toAboutus()
    }
  },
  methods: {
    toAboutus() {
      console.log('aout');
      this.$router.push('/')
      document.getElementById(`about`).scrollIntoView()

    },
    toConcatUs() {
      this.$router.push('/')
      document.getElementById(`contact`).scrollIntoView()

    },
    mouseDownAndMove() {
      const imgswiper = this.$refs.imgswiper
      console.log(imgswiper.scrollLeft );

      if (imgswiper.scrollLeft >= 2212) {
        console.log('lllllllllll');
        imgswiper.scrollLeft = 0
      }
      else if (imgswiper.scrollLeft <= 0) {
        console.log('xxxxxxx');
        imgswiper.scrollLeft = 2211
      }
    },
  }
}
</script>

<style lang="scss" scoped src="./content.scss">
</style>